















import { ModalConfirmComponentData } from '@/types';
import Vue from 'vue';

// Модальное окно для подтверждения действий
export default Vue.extend({
  name: 'ModalConfirm',
  data(): ModalConfirmComponentData {
    return {
      isOpen: false,
      message: '',
      onAccept: undefined,
    };
  },
  methods: {
    accept(): void {
      if (this.onAccept) {
        this.onAccept();
      }
      this.close();
    },
    cancel(): void {
      this.close();
    },
    // Метод для отображения модального окна с нужными данными
    // Функция получает аргументы из события 'open-modal',
    // которое передаётся через эвент-хаб
    open(message: string, onAccept: () => void): void {
      this.message = message;
      this.onAccept = onAccept;
      this.isOpen = true;
    },
    // Закрывает окно и сбрасывает предыдущее состояние
    close(): void {
      this.message = '';
      this.onAccept = undefined;
      this.isOpen = false;
    },
  },
  // При создании компонент подписывается на события, передаваеймые ему через эвент-хаб
  created(): void {
    this.$modalEventHub.$on('open-modal', this.open);
  },
  // При удалении компонент отписывается от этих событий
  beforeDestroy(): void {
    this.$modalEventHub.$off('open-modal');
  },
});
