















































import Vue from 'vue';
import { mapActions } from 'vuex';
import { InputFormWithError } from '@/types';
import MainInputs from '@/components/MainInputs.vue';
import AddCustomField from '@/components/AddCustomField.vue';

export default Vue.extend({
  name: 'NewContactForm',
  components: {
    MainInputs,
    AddCustomField,
  },
  data(): InputFormWithError {
    return {
      // Данные нового контакта
      newContact: {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        customFields: [],
        photoUrl: '',
      },
      // Данные об ошибках
      submitError: {
        message: '',
        fields: {
          firstName: false,
          phoneNumber: false,
        },
      },
    };
  },
  methods: {
    ...mapActions(['addContact']),
    // Добавляет дополнительное поле
    addCustomField(newFieldName: string): void {
      this.newContact.customFields.push({ name: newFieldName, value: '' });
    },
    // Удаляет дополнительное поле
    removeCustomField(name: string): void {
      this.newContact.customFields = this.newContact.customFields.filter(
        (field) => field.name !== name,
      );
    },
    // Добавляет контакт во Vuex при отсутсвии ошибок
    submit(): void {
      if (this.newContact.firstName && this.newContact.phoneNumber) {
        this.addContact(this.newContact);
      } else {
        this.submitError.fields.firstName = !this.newContact.firstName;
        this.submitError.fields.phoneNumber = !this.newContact.phoneNumber;
        this.submitError.message = 'Отмеченные поля обязательны для заполнения';
      }
    },
    // Сбрасывает ошибки
    resetError(): void {
      this.submitError.message = '';
      this.submitError.fields.firstName = false;
      this.submitError.fields.phoneNumber = false;
    },
  },
});
