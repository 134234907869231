



















import Vue from 'vue';
import { mapGetters } from 'vuex';
import ContactPreview from './ContactPreview.vue';

export default Vue.extend({
  name: 'ContactList',
  components: {
    ContactPreview,
  },
  computed: mapGetters(['allContacts']),
});
