





import Vue from 'vue';
import NewContactForm from '@/components/NewContactForm.vue';

export default Vue.extend({
  name: 'Contact',
  components: {
    NewContactForm,
  },
});
