














































import Vue from 'vue';

export default Vue.extend({
  name: 'NewContactForm',
  props: {
    firstNameError: { type: Boolean, required: true },
    phoneNumberError: { type: Boolean, required: true },
    firstName: { type: String, required: true },
    lastName: { type: String, required: true },
    phoneNumber: { type: String, required: true },
    photoUrl: { type: String, required: true },
  },
  // Геттеры и сеттеры для контроля значения полей из родительского компонента
  computed: {
    firstNameValue: {
      get(): string {
        return this.firstName;
      },
      set(value: string): void {
        this.$emit('inputFirstName', value);
      },
    },
    lastNameValue: {
      get(): string {
        return this.lastName;
      },
      set(value: string): void {
        this.$emit('inputLastName', value);
      },
    },
    phoneNumberValue: {
      get(): string {
        return this.phoneNumber;
      },
      set(value: string): void {
        this.$emit('inputPhoneNumber', value);
      },
    },
    photoUrlValue: {
      get(): string {
        return this.photoUrl;
      },
      set(value: string): void {
        this.$emit('inputPhotoUrl', value);
      },
    },
  },
});
