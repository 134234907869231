










import Vue from 'vue';
import Header from '@/components/Header.vue';
import ModalConfirm from '@/components/ModalConfirm.vue';

export default Vue.extend({
  components: {
    Header,
    ModalConfirm,
  },
});
