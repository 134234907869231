



































import Vue from 'vue';
import { mapActions } from 'vuex';
import ButtonWithConfirmationModal from '@/components/ButtonWithConfirmationModal.vue';

export default Vue.extend({
  name: 'ContactPreview',
  components: {
    ButtonWithConfirmationModal,
  },
  props: {
    firstName: { type: String, required: true },
    lastName: { type: String, required: false },
    id: { type: Number, required: true },
    photoUrl: { type: String, required: false },
  },
  computed: {
    deleteModalMessage(): string {
      const name = this.lastName
        ? this.firstName.concat(' ').concat(this.lastName)
        : this.firstName;
      return `Вы уверены что хотите удалить контакт ${name}?`;
    },
    // Используется для постоянного отображения кнопки удаления контакта,
    // если пользователь на устройстве с тач-экраном,
    // тогда как на десктопе кнопка удаления появляется при наведении или фокусе на контакт
    isUserOnTouchScreen(): boolean {
      return !!('ontouchstart' in window);
    },
  },
  methods: mapActions(['deleteContact']),
});
