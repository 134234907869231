


































import Vue, { PropType } from 'vue';
import { NewField, CustomField } from '@/types';

export default Vue.extend({
  name: 'AddCustomField',
  data(): NewField {
    return {
      error: '',
      isOpen: false,
      name: '',
    };
  },
  props: {
    customFields: {
      type: Array as PropType<CustomField[]>,
      required: true,
    },
  },

  methods: {
    // Добавляет новое поле в форму (передаёт данные в родителя)
    addCustomField(): void {
      if (this.name && this.customFields.every((field: CustomField) => field.name !== this.name)) {
        this.$emit('new-custom-field-added', this.name);
        this.resetError();
      } else if (this.name) {
        this.error = 'Поле с таким именем уже существует';
      } else {
        this.error = 'Введите имя поля';
      }
    },
    cancelCustomField(): void {
      this.resetError();
    },
    openCustomField(): void {
      this.isOpen = true;
    },
    // Сбрасывает данные и закрывает инпут для нового поля
    resetError(): void {
      this.name = '';
      this.isOpen = false;
      this.error = '';
    },
  },
});
