

















































import Vue from 'vue';
import ButtonWithConfirmationModal from '@/components/ButtonWithConfirmationModal.vue';

export default Vue.extend({
  name: 'ContactCardField',
  components: {
    ButtonWithConfirmationModal,
  },
  data() {
    return {
      isEditing: false,
      inputValue: this.value,
    };
  },
  props: {
    name: { type: String, required: true },
    value: { type: String, required: true },
    update: { type: Function, required: true },
    delete: { type: Function, required: true },
  },
  methods: {
    inputEnterKeyHandler(e: KeyboardEvent): void {
      if (e.key === 'Enter') {
        this.updateHandler();
      }
    },
    // Обновляет значение поля
    updateHandler(): void {
      this.update({ name: this.name, value: this.inputValue });
      this.isEditing = false;
    },
    // Удаляет поле
    deleteHandler(): void {
      this.delete({ name: this.name, value: this.inputValue });
    },
    // Отмена редактирования поля, сброс значения инпута к изначальному
    cancelEditing(): void {
      this.inputValue = this.value;
      this.isEditing = false;
    },
    // Переход в режим редактирования значения поля
    startEditingHandler(): void {
      this.inputValue = this.value;
      this.isEditing = true;
    },
  },
});
