











































































































import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import { Contact, CustomField, ContactCardData } from '@/types';
import ContactCardField from '@/components/ContactCardField.vue';
import MainInputs from '@/components/MainInputs.vue';
import AddCustomField from '@/components/AddCustomField.vue';

export default Vue.extend({
  name: 'ContactCard',
  components: {
    ContactCardField,
    MainInputs,
    AddCustomField,
  },
  data(): ContactCardData {
    return {
      // Обект для контроля за произошедшими изменениями
      previousState: {
        steps: [],
        currentStep: -1,
      },
      // Редим редактирования
      isEditing: false,
      // Здесь хранится состояние основных полей контака во время его редактирования...
      editingState: {
        contact: {
          firstName: '',
          lastName: '',
          phoneNumber: '',
          photoUrl: '',
          // ... + новые поля
          newCustomFields: [],
        },
        // Информация об ошибках
        submitError: {
          message: '',
          fields: {
            firstName: false,
            phoneNumber: false,
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters(['getById']),
    currentState(): Contact {
      return this.getById(this.id);
    },
    isUndoButtonVisible(): boolean {
      return !(this.previousState.currentStep < 1) && !this.isEditing;
    },
    isRedoButtonVisible(): boolean {
      return (
        !(this.previousState.currentStep + 1 === this.previousState.steps.length) && !this.isEditing
      );
    },
  },
  props: {
    id: { type: Number, required: true },
  },
  methods: {
    ...mapActions(['updateContact']),
    // Отправляет новые данные контакта во Vuex...
    // ...сохраняет предыдущее состояние для возможности отмены действия
    commitChange(updatedContact: Contact): void {
      const clonedCurrentState = cloneDeep(updatedContact);
      if (this.previousState.steps.length > this.previousState.currentStep + 1) {
        this.previousState.steps.splice(this.previousState.currentStep + 1);
      }
      this.previousState.steps.push(clonedCurrentState);
      this.previousState.currentStep += 1;
      this.updateContact(updatedContact);
    },
    // Обновляет значение дополнительного поля контакта
    updateField(field: CustomField): void {
      const newState = cloneDeep(this.currentState);
      const fieldIndex = newState.customFields.findIndex((f: CustomField) => f.name === field.name);
      newState.customFields[fieldIndex] = field;
      this.commitChange(newState);
    },
    // Добавялет новое поле к контакту в режиме редактирования основных данных
    addCustomFieldWhileEditing(newFieldName: string): void {
      this.editingState.contact.newCustomFields.push({ name: newFieldName, value: '' });
    },
    // Удаляет новое поле к контакту в режиме редактирования основных данных
    removeCustomFieldWhileEditing(name: string): void {
      this.editingState.contact.newCustomFields = this.editingState.contact.newCustomFields.filter(
        (field) => field.name !== name,
      );
    },
    // Удаляет существующее поле
    deleteField(field: CustomField): void {
      const newState: Contact = cloneDeep(this.currentState);
      const fieldIndex = newState.customFields.findIndex((f: CustomField) => f.name === field.name);
      newState.customFields.splice(fieldIndex, 1);
      this.commitChange(newState);
    },
    // Шаг назад
    undoChange(): void {
      if (this.previousState.currentStep < 1) {
        return;
      }
      this.previousState.currentStep -= 1;
      this.updateContact(this.previousState.steps[this.previousState.currentStep]);
    },
    // Шаг вперёд
    redoChange(): void {
      if (this.previousState.currentStep + 1 === this.previousState.steps.length) {
        return;
      }
      this.previousState.currentStep += 1;
      this.updateContact(this.previousState.steps[this.previousState.currentStep]);
    },
    // Обновляет основные данные (и добавляет новые поля) изменённые в ходе редактирования контакта
    updateContactInfo(): void {
      if (this.editingState.contact.firstName && this.editingState.contact.phoneNumber) {
        // Сохраняем новую контактную информацию
        const newState = cloneDeep(this.currentState);
        newState.firstName = this.editingState.contact.firstName;
        newState.lastName = this.editingState.contact.lastName;
        newState.phoneNumber = this.editingState.contact.phoneNumber;
        newState.photoUrl = this.editingState.contact.photoUrl;
        newState.customFields = [
          ...newState.customFields,
          ...this.editingState.contact.newCustomFields,
        ];
        this.commitChange(newState);
        this.isEditing = false;
        this.resetErrors();
      } else {
        this.editingState.submitError.fields.firstName = !this.editingState.contact.firstName;
        this.editingState.submitError.fields.phoneNumber = !this.editingState.contact.phoneNumber;
        this.editingState.submitError.message = 'Отмеченные поля обязательны для заполнения';
      }
    },
    // Отмена обновления контактной информации
    cancelUpdateOfContactInfo(): void {
      this.setEditingStateToProps();
      this.isEditing = false;
      this.resetErrors();
    },
    resetErrors(): void {
      this.editingState.submitError.fields.firstName = false;
      this.editingState.submitError.fields.phoneNumber = false;
      this.editingState.submitError.message = '';
    },
    setEditingStateToProps(): void {
      this.editingState.contact.firstName = this.currentState.firstName;
      this.editingState.contact.lastName = this.currentState.lastName;
      this.editingState.contact.phoneNumber = this.currentState.phoneNumber;
      this.editingState.contact.photoUrl = this.currentState.photoUrl;
    },
  },
  created(): void {
    this.setEditingStateToProps();

    const clonedCurrentState = cloneDeep(this.currentState);
    this.previousState.steps.push(clonedCurrentState);
    this.previousState.currentStep += 1;
  },
});
